import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import Modal from "../../../../../Modal"
import Avatar from "../../../../../Avatar"
import { closeUnassignedThreadViewModal } from "../../../../../../state/unassignedThreadViewModal"
import { openUnassignedThreadAssignModal } from "../../../../../../state/unassignedThreadAssignModal"

export default function UnassignedThreadViewModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedThreadViewModal" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })
  const { open, thread } = useSelector(({ unassignedTreadViewModal }) => unassignedTreadViewModal)
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeUnassignedThreadViewModal())
  }

  const handlePrimaryAction = () => {
    onClose()
    if (thread) dispatch(openUnassignedThreadAssignModal({ unassignedThreadId: thread.id }))
  }

  if (!thread) return null

  return (
    <Modal
      className="cp_component_unassigned-thread-view-modal"
      open={ open }
      closeAction={ onClose }
      primaryLabel={ translate("Assign To Group") }
      secondaryLabel={ tCommon("Cancel") }
      primaryAction={ handlePrimaryAction }
      secondaryAction={ onClose }
      maxWidth="xl"
      title={ thread.subject }
    >
      <div className="modal-body">
        <div>
          <Avatar
            id="cp_component_unassigned-thread-view-email-avatar"
            size="md"
            className="avatar"
          >
            { thread.firstMessage.from.address[0].toUpperCase() }
          </Avatar>
        </div>
        <div className="content-container">
          <p id="cp_component_unassigned-thread-view-email-sender" className="email-sender">{ `${ translate("From") }: ${ thread.firstMessage.from.address }` }</p>
          <p id="cp_component_unassigned-thread-view-email-content" className="email-content">{ thread.firstMessage.text }</p>
          <p id="cp_component_unassigned-thread-view-email-signature" className="email-signature">{ thread.firstMessage.signature }</p>
        </div>
      </div>
    </Modal>
  )
}
