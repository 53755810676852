import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction, Dispatch } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

export interface BrandManagersPermissionsModal {
  open: boolean
  availableCampaigns: Status<GraphQL.GetAvailableCampaignsQuery>
}

const initialState: BrandManagersPermissionsModal = {
  open: false,
  availableCampaigns: "init",
}

// Deliverable Content Modal Slice
export const BrandManagersPermissionsModalSlice = createSlice({
  name: "BrandManagersPermissionsModalSlice",
  initialState,
  reducers: {
    openBrandManagersPermissionsModal: (
      state,
    ) => ({
      ...state,
      open: true,
    }),
    setAvailableCampaigns: (
      state,
      action: PayloadAction<Status<GraphQL.GetAvailableCampaignsQuery>>,
    ) => ({
      ...state,
      availableCampaigns: action.payload,
    }),
    closeBrandManagersPermissionsModal: (
      state,
    ) => ({
      ...state,
      open: false,
      availableCampaigns: "init",
    }),
  },
})

export const {
  closeBrandManagersPermissionsModal,
  openBrandManagersPermissionsModal,
  setAvailableCampaigns,
} = BrandManagersPermissionsModalSlice.actions
export default BrandManagersPermissionsModalSlice.reducer

export const getAvailableCampaigns = (
  variables: GraphQL.GetAvailableCampaignsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAvailableCampaigns("loading"))
  const result = await API.getAvailableCampaigns(variables)

  dispatch(setAvailableCampaigns(result))
}

export const createCampaignPermission = (
  params: {
    variables: GraphQL.CreateCampaignPermissionMutationVariables
    onSuccess: () => void,
    onError: () => void
  },
) => async (
  dispatch: Dispatch,
) => {
  const result = await API.createCampaignPermission(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}
