import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface ModalCommGroupState {
  commGroupModalOpen: boolean,
  lists: Status<GraphQL.SearchListsQuery>
}

// Initialize state
const initialState: ModalCommGroupState = {
  commGroupModalOpen: false,
  lists: "init",
}

export const ModalcommGroupSlice = createSlice({
  name: "ModalCommGroup",
  initialState,
  reducers: {
    setCommGroupModalOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      commGroupModalOpen: action.payload,
    }),
    setLists: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListsQuery>>,
    ) => ({
      ...state,
      lists: action.payload,
    }),
  },
})

export const { setCommGroupModalOpen, setLists } = ModalcommGroupSlice.actions
export default ModalcommGroupSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchLists = (
  network: GraphQL.Network | GraphQL.Network[],
  startsWith?: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLists("loading"))

  const listResults = await API.fetchLists({
    startsWith,
    networkFilter: network,
    limit: 20,
  })

  dispatch(setLists(listResults))
}
