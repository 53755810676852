import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Campaign, Check } from "@mui/icons-material"
import { Badge, Tooltip } from "@mui/material"

import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import IconButton from "../../IconButton"
import LoadingIndicator from "../../LoadingIndicator"
import ModalScoreBreakDown from "../../ModalScoreBreakDown"
import NetworkAvatar from "../../NetworkAvatar"
import Pill from "../../Pill"
import Tags from "../../ProfileOverview/Tags"
import { ScoreBreakDown } from "../../../util/types"
import { SuggestionListMode } from "../../../graphql"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../../state/scoreBreakDownSlice"
import { scoreModalTypes } from "../../../util/constant"
import { setLeftPanelStatus, setRightPanelStatus } from "../../../state/publicListSlice"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  formatPercent,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../../util/miscHelper"

import "./public-list-account-header.sass"

export default function PublicListAccountHeader() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListAccountHeader" })
  const dispatch = useDispatch()
  const {
    list, loadedListSocialAccount, rightPanelStatus,
  } = useSelector(({ publicList }) => publicList)

  const [ engagementScoreModal, setEngagementScoreModal ] = useState(false)

  useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null)) return
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType))
    setEngagementScoreModal(true)
  }

  if (loadedListSocialAccount === "init" || loadedListSocialAccount === "loading" || list === "loading" || list === "init") {
    return <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
  }

  if (API.isError(list) || API.isError(loadedListSocialAccount)) {
    return <p>There was an error. Please try again!</p>
  }

  const {
    age: listSocialAccountAge,
    avatar: listSocialAccountAvatar,
    location: listSocialAccountLocation,
    bio: listSocialAccountBio,
    gender: listSocialAccountGender,
    name: listSocialAccountName,
    verticals: listSocialAccountVerticals,
    inDemo,
    socialAccount: {
      networkUrl,
      userName,
      network,
      name: socialAccountName,

      socialAccountStatistics: {
        followers,
      },
      profilePictureUrl,
      bio: socialAccountBio,
    },
    score,
    suggestionList: {
      verticals,
    },
    approvalStatus,
    comments,
  } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId

  const { suggestionListMode, toggles } = list.payload.publicSuggestionListByCode
  const fullSocialAccount = loadedListSocialAccount
    .payload
    .publicSuggestionListSocialAccountByListCodeSocialAccountId
    .socialAccount

  const subInfo = []
  if (listSocialAccountLocation) subInfo.push(listSocialAccountLocation)
  if (listSocialAccountAge) subInfo.push(listSocialAccountAge)
  if (listSocialAccountGender) subInfo.push(listSocialAccountGender)

  const approveButtonClass = [
    "approve-button",
    approvalStatus?.value ? "approved" : "",
  ].join(" ")

  const hasEngagmentRate = toggles.some((toggle) => toggle.name === "ToggleEngagementRate")
  const displayVerticals = listSocialAccountVerticals.length > 0 || verticals.length > 0
  const displayedVerticals = listSocialAccountVerticals.length > 0 ? listSocialAccountVerticals : verticals

  const getEngagementRate = (components: GraphQL.ScoreComponent[]) => {
    const engagementComponent = components.find((component) => component.display === "Engagement Rate")
    return engagementComponent ? formatPercent(2, engagementComponent.raw) : 0
  }
  let activeScore: null | string | number = null
  let scoreLabel = translate("SCORE")

  if (score) {
    if (hasEngagmentRate) {
      activeScore = getEngagementRate(score?.components)
      scoreLabel = translate("ENG. RATE")
    } else {
      activeScore = Math.round(score?.value || 0)
    }
  }

  return (
    <div className="cp_profile_component-list-tabs-account-header">
      <div className="cp_profile_component-list-tabs-account-header-main">
        <div className="cp_profile_component-list-tabs-account-header-main-container">
          <NetworkAvatar size="xl" network={ network } src={ listSocialAccountAvatar?.url.address || profilePictureUrl } />
          <div className="cp_profile_component-list-tabs-account-header-main-container-info">
            <a href={ networkUrl || "" } target="_blank" rel="noreferrer">
              <h2>{ userName }</h2>
            </a>
            <h4>{ listSocialAccountName || socialAccountName }</h4>
            <h5>{ subInfo.join(" | ") }</h5>
            <div className="cp_profile_component-list-tabs-account-header-main-container-info-scores">
              <div>
                <p className="label_small-caps-semibold">
                  { translate("FOLLOWERS") }
                </p>
                <Pill label={ shorthandNumber(followers) } color="warning" />
              </div>
              { inDemo !== null && (
              <div>
                <p className="label_small-caps-semibold">
                  { translate("In-demo") }
                </p>
                <Pill label={ `${ prettyPrintDecimal(inDemo) }%` } color="info" />
              </div>
              ) }
              { score && (
              <div>
                <p className="label_small-caps-semibold">
                  { scoreLabel }
                </p>
                <Pill
                  label={ activeScore }
                  handleClick={
                fullSocialAccount ? () => {
                  const scoreBreakDown = {
                    // NOTE: this component uses only a fragment of socialAccount and score
                    socialAccount: fullSocialAccount as GraphQL.SocialAccount,
                    scores: [ score as GraphQL.Score ],
                  }
                  handleEngagmentScoreModal(scoreBreakDown, scoreModalTypes.ENGAGEMENT)
                }
                  : () => {}
               }
                />
              </div>
              ) }
            </div>
          </div>
        </div>
        { suggestionListMode === SuggestionListMode.Campaign
      && (
        <div className="cp_profile_component-list-tabs-account-header-main-buttons">
          <Tooltip title={ approvalStatus?.value ? translate("Approved") : translate("Approve") }>
            <IconButton
              className={ approveButtonClass }
              variant={ approvalStatus?.value || rightPanelStatus === "approve" ? "filled" : "outlined" }
              onClick={ () => {
                if (!approvalStatus?.value) {
                  dispatch(setLeftPanelStatus("closed"))
                  dispatch(setRightPanelStatus("approve"))
                }
              } }
            >
              <Check />
            </IconButton>
          </Tooltip>
          <Badge color="primary" className="feedback-badge" badgeContent={ comments.length || null }>
            <Tooltip title={ translate("Leave Feedback") }>
              <IconButton
                className="feedback-button"
                variant={ rightPanelStatus === "feedback" ? "filled" : "outlined" }
                onClick={ () => {
                  dispatch(setLeftPanelStatus("closed"))
                  dispatch(setRightPanelStatus("feedback"))
                } }
              >
                <Campaign />
              </IconButton>
            </Tooltip>
          </Badge>
        </div>
      ) }
      </div>
      <div className="cp_profile_component-list-tabs-account-header-sub">
        <p className="cp_profile_component-list-tabs-account-header-sub-bio-title">
          { translate("BIOGRAPHY") }
        </p>
        <p className="cp_profile_component-list-tabs-account-header-sub-bio">
          { listSocialAccountBio || socialAccountBio }
        </p>
        { displayVerticals && (
        <div className="cp_profile_component-list-tabs-account-header-sub-tags">
          <Tags verticals={ displayedVerticals } color="success" />
        </div>
        ) }
      </div>
      <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </div>
  )
}
