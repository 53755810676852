import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

export interface UnassignedThreadViewModalState {
  open: boolean
  thread?: GraphQL.ConversationThreadFragment
}

const initialState: UnassignedThreadViewModalState = {
  open: false,
}

export const unassignedThreadViewModal = createSlice({
  name: "UnassignedThreadViewModal",
  initialState,
  reducers: {
    openUnassignedThreadViewModal: (
      state,
      action: PayloadAction<GraphQL.ConversationThreadFragment>,
    ) => ({
      ...state,
      open: true,
      thread: action.payload,
    }),
    closeUnassignedThreadViewModal: (
      state,
    ) => ({
      ...state,
      open: false,
      thread: undefined,
    }),
  },
})

export const {
  closeUnassignedThreadViewModal,
  openUnassignedThreadViewModal,
} = unassignedThreadViewModal.actions
export default unassignedThreadViewModal.reducer
