import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import {
  Add,
  Circle,
  NotificationsNone,
  Close,
  Search,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import NetworkIcon from "../NetworkIcon"
import Input from "../Input"
import Modal from "../Modal"
import ListsAutoComplete from "../ModalCampaign/ListsAutocomplete"
import { setCommGroupModalOpen } from "../../state/ModalCommGroupSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function ModalCommGroup(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCommGroup" })
  const [ selectedLists, selectLists ] = React.useState<Array<GraphQL.SuggestionListRowFragment>>([])

  const dispatch = useDispatch()
  const { commGroupModalOpen } = useSelector(({ commGroupModal }) => commGroupModal)

  return (
    <Modal
      title={ translate("Create Communication Group") }
      primaryLabel={ translate("Create Group") }
      secondaryLabel={ translate("Cancel") }
      open={ commGroupModalOpen }
      closeAction={ () => dispatch(setCommGroupModalOpen(false)) }
      secondaryAction={ () => dispatch(setCommGroupModalOpen(false)) }
      primaryAction={ () => {} }
      maxWidth="xl"
      className="cp_component_modal-comm-group"
    >
      <section className="cp_component_modal-comm-group-form">
        <h5>{ translate("Details") }</h5>
        <Input
          className="cp_component_modal-comm-group-input"
          fullWidth={ true }
          label={ translate("Name") }
          placeholder={ translate("Enter Group Name") }
        />
        <h5>{ translate("Labels") }</h5>
        <Input
          label={ translate("Custom Label") }
          placeholder={ translate("Type Vertical Name") }
          InputProps={ {
            endAdornment: (
              <IconButton>
                <Add />
              </IconButton>
            ),
          } }
        />
        <p className="body_small input_description">
          { translate("Default labels are listed below") }
        </p>
        <h5>{ translate("Lists") }</h5>
        <p>
          { translate("Attach lists to this communication group") }
        </p>
        <p className="body_small">
          { translate("When you attach a list") }
        </p>
        <div className="cp_component_modal-comm-group-form-autocomplete">
          <ListsAutoComplete
            setSelectedLists={ selectLists }
            selectedLists={ selectedLists }
          />
        </div>
        <div className="cp_component_modal-comm-group-form-autocomplete-list">
          { selectedLists.map((list) => (
            <div key={ list.id }>
              <p className="cp-modal-comm-list-info">
                <NetworkIcon network={ list.network } isActive={ true } />
                <span>{ list.name }</span>
              </p>
              <IconButton
                size="small"
                onClick={ () => selectLists(selectedLists.filter((l) => l.id !== list.id)) }
              >
                <Close />
              </IconButton>
            </div>
          ))
            }
        </div>
      </section>
      <section className="cp_component_modal-comm-side-options">
        <article>
          <h5>{ translate("Campaign") }</h5>
          <p>
            { translate("Attach a campaign to this communication group") }
          </p>
          <p className="body_small">
            { translate("When you attach a campaign") }
          </p>
          <Input
            className="cp_component_modal-comm-group-input"
            fullWidth={ true }
            placeholder={ translate("Enter Campaign Name") }
            InputProps={ {
              startAdornment: <Search />,
            } }
          />
        </article>
        <article>
          <aside>
            <NotificationsNone />
            <h5>{ translate("Notifications") }</h5>
          </aside>
          <div className="cp_component_modal-comm-group-notifications-info">
            <p>
              { translate("Notify team members when there is activity") }
            </p>
            <ul>
              <li>
                <Circle />
                { translate("New conversation started") }
              </li>
              <li>
                <Circle />
                { translate("Replied to conversation") }
              </li>
              <li>
                <Circle />
                { translate("Content uploaded") }
              </li>
              <li>
                <Circle />
                { translate("Deliverable created") }
              </li>
            </ul>
          </div>
          <Input
            className="cp_component_modal-comm-group-list-input"
            fullWidth={ true }
            label={ translate("Subscribe Team Members") }
            placeholder={ translate("Type Team Member Name") }
            InputProps={ {
              startAdornment: <Search />,
            } }
          />
        </article>
      </section>
    </Modal>
  )
}
